body {
    margin: 0;
    font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    text-align: left;
    background-color: #222
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-in {
    animation: fadeInAnimation 0.2s linear;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.list-img-thumb {
    max-height: 30px;
    max-width: 30px;
    margin: 5px;
}

.detail-img-thumb {
    height: 30px;
    margin: 5px;
}

.detail-img-thumb-large {
    height: 50px;
    margin: 5px;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 30px;
    background-color: #303030
}

.footer-content {
    position: relative;
    left: 0;
    bottom: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.top-padding {
    padding-top: 16px;
}

.bottom-margin {
    margin-bottom: 75px;
}

.list-group-img {
    position: absolute;
    width: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
}

.list-group-item-left-link {
    color: #fff;
    width: calc(100% - 30px);
    float: left;
    padding: 0.75rem 1.25rem;
    text-align: left;
}

.list-group-item-left-link:hover {
    color: #ddd;
    text-decoration: underline;
}

.list-group-small-link {
    position: absolute;
    width: 30px;
    height: 100%;
    padding: 0.75rem 0;
}

.list-group-item.no-padding {
    padding: 0;
}

.mobile {
    display: none;
}

@media all and (max-width: 767px) {
    .mobile {
        display: block !important;
    }

    .desktop {
        display: none !important;
    }
}

.small-header {
    font-size: 1.8rem;
}

.small-header.mobile {
    margin-top: 15px;
}

.mobile-spacing {
    margin-bottom: 20px;
    margin-top: -5px;
    font-weight: 800;
    font-size: 90%;
}

.desktop-spacing {
    margin-bottom: 20px;
    margin-top: 5px;
    font-weight: 800;
    font-size: 50%;
    white-space: nowrap;
    display: inline-block;
}

.mirror-list {
    margin: 0 0 10px 0;
}

.mirror-item {
    border-top-style: none;
}

.mirror-link {
    padding-left: 40px;
}

.list-hour-mobile {
    display: inline-block;
    vertical-align: top;
    margin-top: 25px;
    margin-right: 15px;
}

.list-hour-desktop {
    display: inline-block;
    margin-right: 15px;
}

.list-hour-desktop.show-date {
    vertical-align: bottom;
}

.list-match-result {
    display: inline-block;
}

.list-group-item {
    z-index: 0;
}

.list-group-flex {
    display: flex;
}

.list-group-item:hover {
    z-index: 0;
}

.ad-footer-div {
    z-index: 100;
    position: absolute;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.right-ad {
    position: absolute;
    top: 70px;
    right: 0;
}

.footer-iframe {
    border: none;
    width: 320px;
}

.side-iframe {
    border: none;
    width: 160px;
    height: 610px;
}

.br-spacing {
    margin: 5px;
    display: block;
    content: '';
}

.detail-score {
    margin-right: 5px;
    font-size: 90%;
    background: #303030;
    padding: 5px 8px 5px 8px;
    border-radius: 0.25rem;
    margin-bottom: 5px
}

.detail-score-desktop {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 90%;
    background: #303030;
    padding: 5px 8px 5px 8px;
    border-radius: 0.25rem;
    margin-bottom: 5px
}

.list-score {
    margin-right: 5px;
    font-size: 90%;
    background: #282828;
    padding: 5px 8px 5px 8px;
    border-radius: 0.25rem;
}

.list-score-desktop {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 90%;
    background: #282828;
    padding: 5px 8px 5px 8px;
    border-radius: 0.25rem;
}

.small-margin-right {
    margin-right: 10px;
}

.small-btn {
    width: 45px;
}

.small-margin-bottom {
    margin-bottom: 20px;
}

/*********************************/
/* Select 2 theme customizations */
/*********************************/

.select2-el-label {
    margin-bottom: 20px;
    width: 100%;
}

.select2-el {
    width: 100%;
}

.select2-selection.select2-selection--single {
    background-color: #444;
}

.select2-container--default .select2-selection--single {
    border: #444;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #fff;
}

.select2-dropdown {
    background-color: #222;
    border-color: #444;
}

.select2-results__option {
    color: #999;
}

.select2-search__field {
    background-color: #444;
    color: #fff;
}

/***************************************/
/* DateTimePicker theme customizations */
/***************************************/

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #00bc8c;
}

.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: #00bc8c;
}

.bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
    background-color: #00efb2;
}

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
    background-color: #00efb2;
}

.bootstrap-datetimepicker-widget table td span.active {
    background-color: #00bc8c;
}

.bootstrap-datetimepicker-widget table td span:hover {
    background-color: #00efb2;
}

.glyphicon-chevron-left:before {
    content: "\2039"
}

.glyphicon-chevron-right:before {
    content: "\203A"
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.collapse-open {
    background-color: #666;
}

.collapse-open:hover {
    background-color: #888;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

a.list-group-item.list-group-item-action {
    border-radius: 0.25rem;
}

.spinner-item span {
    color: #00bc8c;
    border-color: #00bc8c;
}

.small-text {
    font-size: 9pt;
}

.light-text {
    color: #888888;
}

.big-text {
    font-size: 40pt;
}

.center-text {
    text-align: center;
}

.no-wrap {
    white-space: nowrap
}

.team-link {
    text-decoration: none;
    color: inherit;
}

.team-link:hover {
    text-decoration: none;
    color: #ddd;
}

.team-link:hover .detail-score {
    color: white;
}

.adplus-responsive {
    margin-bottom: 20px;
}

.floating-div {
    /* For 1xbet */
    position: fixed;
    bottom: 0; /* Distance from bottom */
    left: 50%;
    transform: translateX(-50%);
    /* Optional styling */
    padding: 0;
    z-index: 1000;
}